<template>
  <div class="dfpHome">
	  <div id="demo"></div>
  </div>
</template>
<script>
  import Pdfh5 from "pdfh5";
  import "pdfh5/css/pdfh5.css";
  export default {
    name: 'dfpHome',
    props:["nkpdfUrl"],
	data() {
	  return {
	    pdfh5: null,
      numPages: '',
      src: '',
      pdfUrl1:'http://storage.xuetangx.com/public_assets/xuetangx/PDF/PlayerAPI_v1.0.6.pdf'
	  };
	},
	mounted() {
		// console.log(this.nkpdfUrl)
    this.pdf5()
	},
  components:{
    // pdf
  },
  watch:{},
  methods:{
    pdf5(){
      // window.location.href ='http://nankai.tiptimes.com/file/2021/09/18/445bdafae9c148a096dcbbe328727110.pdf'
      //实例化
      // console.log(this.nkpdfUrl)
      this.pdfh5 = new Pdfh5("#demo", {
        pdfurl: this.nkpdfUrl,
        zoomEnable:false,
        lazy:true
      });
      //监听完成事件
      this.pdfh5.on("complete", function (status, msg, time) {
        console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
      })
    }
  }
  }
</script> 

<style lang="less" scoped>
  // @
	*{
	padding: 0;
	margin: 0;
	}
  .dfpHome{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
  }
  #demo{
    // height: 100%;
  }
</style>